module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"bodyOpenClassName":"ReactModal__Body--open","htmlOpenClassName":"ReactModal__Html--open","overlayClassName":"ReactModal__Overlay","className":"ReactModal__Content"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Panel zarządzania","htmlFavicon":"src/assets/images/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Strona przedszkola Przytulanka","short_name":"Przedszkola Przytulanka","lang":"pl","start_url":"/","background_color":"#6A1485","theme_color":"#6A1485","display":"minimal-ui","icon":"src/assets/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1aeff7823707d026c4b66e51fd065e31"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
