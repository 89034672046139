// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-formularz-kontaktowy-js": () => import("./../../../src/pages/formularz_kontaktowy.js" /* webpackChunkName: "component---src-pages-formularz-kontaktowy-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-grupy-i-plan-dnia-js": () => import("./../../../src/pages/grupy_i_plan_dnia.js" /* webpackChunkName: "component---src-pages-grupy-i-plan-dnia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o_nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-templates-bio-index-js": () => import("./../../../src/templates/Bio/index.js" /* webpackChunkName: "component---src-templates-bio-index-js" */),
  "component---src-templates-reference-index-js": () => import("./../../../src/templates/Reference/index.js" /* webpackChunkName: "component---src-templates-reference-index-js" */),
  "component---src-templates-rules-index-js": () => import("./../../../src/templates/Rules/index.js" /* webpackChunkName: "component---src-templates-rules-index-js" */)
}

